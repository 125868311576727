import missingPage from 'assets/images/missing-page.svg';
import Button, { ButtonVariants } from 'components/button/button';
import Logo from 'components/logo/logo';
import { ROUTES } from 'constants/index';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './pageNotfound.module.scss';

// const missingPage =  'assets/images/missing-page.svg'

function PageNotFound(): JSX.Element {
  return (
    <div className={`flex-center flex-col ${styles.pageNotfound}`}>
      <Logo className={styles.logo} variant="onboarding" />
      <img className={styles.pageNotfoundImg} src={missingPage} alt="" />
      {/* <div className={`flex-center  ${styles.pageNotfoundImg}`} >Asset required</div> */}
      <p className={styles.errorMessage}>
        Whoops. It looks like we have misplaced this page.
      </p>
      <Link to={ROUTES.login}>
        <Button
          className={styles.gotoScreenBtn}
          variant={ButtonVariants.MediumStd}
          btnName="Go to Screenz Frontpage"
        />
      </Link>

      {/* <Chat /> */}
    </div>
  );
}

export default PageNotFound;
