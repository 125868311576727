import ContentPreview from 'components/content-preview/contentPreview';
import { cancelIcon, imageIcon, videoIcon } from 'constants/index';
import React from 'react';
import { iUploadingFile } from 'types';
import styles from './fileUploadProgress.module.scss';
import ProgressBar from './progress-bar/progressBar';
import { FileUploadProgressVariants, Variants } from './variant';

interface iProps {
  className?: string;
  variant: FileUploadProgressVariants;
  data: iUploadingFile;
  // data: any;
  cancel?: () => void;
}

function FileUploadProgress(props: iProps): JSX.Element {
  const { className, variant, data, cancel } = props;
  const { showContentIcon, showPreview } = Variants[variant];
  const { file, progress } = data;
  const { type = '' } = file || {};

  return (
    <li className={`${styles.file} ${styles[variant]} ${className}`}>
      <div className={`${styles.main} ${styles[variant]}`}>
        {showContentIcon && (
          <img
            src={
              (type.includes('image') && imageIcon) ||
              (type.includes('video') && videoIcon) ||
              imageIcon
            }
            alt=""
          />
        )}

        {data && showPreview && (
          <ContentPreview
            className={styles.preview}
            data={{
              url: '',
              contentType: file.type,
              id: '',
              name: '',
              size: '',
              dimension: '',
              folder: '',
              company: '',
              thumbnailPath: '',
              duration: '',
            }}
          />
        )}
        <div className={`${styles.fileMeta} ${styles[variant]}`}>
          <p className={styles.nameAndSize}>
            <p className={styles.name}>
              {file.name ? file.name.slice(0, 15) : 'Your File'}...
            </p>
            {variant === FileUploadProgressVariants.InModal && (
              <span className={styles.size}>
                {(file.size / (1024 * 1024)).toFixed(2)}MB
              </span>
            )}
          </p>
          {variant === FileUploadProgressVariants.OffModal && (
            <p className={styles.contentMeta}>
              <span>Video</span>
              <span>/</span>
              <span>1920*1090</span>
              <span>-</span>
              <span>16MB</span>
            </p>
          )}
          {variant === FileUploadProgressVariants.InModal && (
            <ProgressBar progress={progress} />
          )}
        </div>
      </div>
      {variant === FileUploadProgressVariants.OffModal && (
        <ProgressBar progress={progress} />
      )}
      <div className={styles.cancel} onClick={cancel && cancel}>
        <span>Cancel</span>
        <img className={styles.cancelIcon} src={cancelIcon} alt="" />
      </div>
    </li>
  );
}

FileUploadProgress.defaultProps = {
  className: '',
  cancel: () => {},
};

export { FileUploadProgressVariants };
export default FileUploadProgress;
