import Button, { ButtonVariants } from 'components/button/button';
import React from 'react';
import styles from './headerTitle.module.scss';

type iProps = {
  isDisabled: boolean;
  onCancelChanges?: () => void;
  onSaveChanges?: () => void;
};

function HeaderTitle(props: iProps): JSX.Element {
  const { isDisabled, onCancelChanges, onSaveChanges } = props;
  return (
    <div className={styles.headerContainer}>
      <Button
        btnName="Cancel"
        variant={ButtonVariants.SmallStd}
        className={styles.cancelBtn}
        onClick={onCancelChanges}
      />
      <Button
        btnName="Save Changes"
        variant={ButtonVariants.SmallStd}
        isDisabled={!isDisabled}
        onClick={onSaveChanges}
      />
    </div>
  );
}

HeaderTitle.defaultProps = {
  onCancelChanges: () => {
    //
  },
  onSaveChanges: () => {
    //
  },
};

export default HeaderTitle;
