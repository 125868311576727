import React from 'react';

interface iProps {
  className?: string;
}

function Screen(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 6c0-.558.442-1 1-1h18a1 1 0 0 1 1 1v10c0 .548-.452 1-1 1H3a1 1 0 0 1-1-1V6zm1-3C1.338 3 0 4.338 0 6v10a3 3 0 0 0 3 3h18c1.652 0 3-1.348 3-3V6a3 3 0 0 0-3-3H3zm4 17a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2H7z"
        fill="#fff"
      />
    </svg>
  );
}

Screen.defaultProps = {
  className: '',
};

export default Screen;
