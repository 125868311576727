import React from 'react';

interface iProps {
  className?: string;
}

function Disconnect(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#tp3uf3ekha)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.039 2.424a.963.963 0 0 1 0 1.362l-3.22 3.22 3.84 3.838 3.219-3.22a.963.963 0 0 1 1.362 1.363l-3.22 3.22 1.92 1.919a.963.963 0 0 1 0 1.362l-2.231 2.23 1.858 1.856a.963.963 0 0 1-1.288 1.429.986.986 0 0 0-.067-.074L3.215 5.959a.977.977 0 0 0-.073-.065.963.963 0 0 1 1.428-1.29l1.364 1.362 2.241-2.242a.963.963 0 0 1 1.363 0l1.92 1.92 3.219-3.22a.963.963 0 0 1 1.362 0zM3.47 8.937c-1.528 2.872-1.257 6.466.813 9.081l-1.31 1.309a.963.963 0 0 0 1.363 1.362l1.309-1.309c2.62 2.073 6.22 2.341 9.094.805l-1.446-1.444c-2.325.975-5.123.52-7.007-1.365-1.881-1.881-2.338-4.672-1.37-6.995L3.471 8.937zm7.297-1.258L8.857 5.767l-1.56 1.56 9.048 9.03 1.551-1.55-1.911-1.912-.008-.007-.008-.008-5.185-5.185-.008-.008-.008-.008z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="tp3uf3ekha">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

Disconnect.defaultProps = {
  className: '',
};

export default Disconnect;
