import Logo from 'components/svg/logo';
import React from 'react';
import styles from './logo.module.scss';

interface iProps {
  className?: string;
  variant?: 'sidebar' | 'onboarding';
}

function LogoComponent(props: iProps): JSX.Element {
  const { className, variant = 'sidebar' } = props;
  return <Logo className={`${className} ${styles.logo}  ${styles[variant]}`} />;
}

LogoComponent.defaultProps = {
  className: '',
  variant: 'sidebar',
};

export default LogoComponent;
