import React from 'react';
import styles from './sectionHeading.module.scss';

interface iProps {
  className?: string;
  heading: string;
}

function SectionHeading(props: iProps): JSX.Element {
  const { className, heading } = props;
  return (
    <div className={`${styles.sectionHeading} ${className}`}>{heading}</div>
  );
}

SectionHeading.defaultProps = {
  className: '',
};

export default SectionHeading;
