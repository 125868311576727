import React from 'react';
import styles from './card.module.scss';

interface iProps {
  className?: string;
  children: JSX.Element;
}

function Card(props: iProps): JSX.Element {
  const { className, children } = props;
  return <div className={`${styles.container} ${className}`}>{children}</div>;
}

Card.defaultProps = {
  className: '',
};

export default Card;
