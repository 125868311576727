import { checkCircleIcon } from 'constants/index';
import React from 'react';
import styles from './company.module.scss';

interface iProps {
  className?: string;
  isActive: boolean;
  isLastOne: boolean;
  onClick: () => void;
  data: any;
}

function Index(props: iProps): JSX.Element {
  const { className, isActive, isLastOne, data, onClick } = props;
  return (
    <div
      className={`
      ${className}
    ${styles.company} 
    ${isActive && styles.active}
    ${isLastOne && styles.last}
    `}
      onClick={onClick}
    >
      <p>{data.name}</p>
      {isActive && <img src={checkCircleIcon} alt="" />}
    </div>
  );
}

Index.defaultProps = {
  className: '',
};

export default Index;
