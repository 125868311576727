import React from 'react';

interface iProps {
  className?: string;
}

function CaretFilledDown(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <svg
      className={className}
      width="40"
      height="30"
      viewBox="7.5 8.9 9 7.2"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.849 10c.977 0 1.468 1.085.927 1.81l-.001.002-2.848 2.726a1.161 1.161 0 0 1-1.853 0l-2.849-2.726-.001-.001A1.135 1.135 0 0 1 9.15 10h5.698z"
        fill="#ACAEC5"
      />
    </svg>
  );
}

CaretFilledDown.defaultProps = {
  className: '',
};

export default CaretFilledDown;
