import Button, { ButtonVariants } from 'components/button/button';
import { playlistEmptyStateImage, whiteAddCircle } from 'constants/index';
import { ModalViews } from 'enums';
import React, { useCallback } from 'react';
import { useStore } from 'store';
import { layoutActions } from 'store/actions';
import styles from './emptyState.module.scss';

interface iProps {
  className?: string;
}

function EmptyState(props: iProps): JSX.Element {
  const { className } = props;
  const [, dispatch] = useStore();

  const showAddContentModal = useCallback(() => {
    dispatch(
      layoutActions.toggleModal(ModalViews.isShowAssignContentOrPlaylist)
    );
  }, [dispatch]);

  return (
    <div className={`${styles.emptyPlaylist} ${className}`}>
      <img
        className={styles.playlistEmptyStateImage}
        src={playlistEmptyStateImage}
        alt=""
      />
      <p className={styles.heading}>Your Playlist</p>
      <p className={styles.description}>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua.
      </p>
      <Button
        className={styles.addContent}
        variant={ButtonVariants.SmallStd}
        btnName="Add your first content"
        icon={whiteAddCircle}
        onClick={showAddContentModal}
      />
    </div>
  );
}

EmptyState.defaultProps = {
  className: '',
};

export default EmptyState;
