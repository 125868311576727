import React from 'react';

interface iProps {
  className?: string;
}

function CloseCircle(props: iProps): JSX.Element {
  const { className } = props;

  return (
    <svg
      className={className}
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.333a6.667 6.667 0 1 0 0 13.334A6.667 6.667 0 0 0 8 1.333zM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm10.358-2.358c.26.26.26.682 0 .943L8.943 8l1.415 1.415a.667.667 0 0 1-.943.943L8 8.943l-1.415 1.415a.667.667 0 0 1-.943-.943L7.057 8 5.642 6.585a.667.667 0 1 1 .943-.943L8 7.057l1.415-1.415c.26-.26.683-.26.943 0z"
        fill="#8A8DAA"
      />
    </svg>
  );
}

CloseCircle.defaultProps = {
  className: '',
};

export default CloseCircle;
