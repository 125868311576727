import { Query, Mutation } from 'config';
import { captureException } from 'helpers/sentry';
import {
  iGraphqlResponse,
  iGetSystemConfigsResponse,
  iSystemCOnfigData,
  iUpdateSystemConfigArgs,
} from 'types';
import { getSystemConfigsQuery, updateSystemConfigMutation } from '../graphql';

export const getAllSystemConfigs =
  async (): Promise<Array<iSystemCOnfigData> | null> => {
    try {
      const response = <iGraphqlResponse<iGetSystemConfigsResponse>>(
        await Query(getSystemConfigsQuery)
      );
      return response.data.getsystemconfigs.data;
    } catch (error: any) {
      captureException(error);
      return null;
    }
  };

export const updateSystemConfig = async (
  args: iUpdateSystemConfigArgs
): Promise<boolean | null> => {
  try {
    const response = <iGraphqlResponse<iGetSystemConfigsResponse>>(
      await Mutation(updateSystemConfigMutation, args)
    );
    if (response) return true;
    return false;
  } catch (error: any) {
    captureException(error);
    return null;
  }
};
