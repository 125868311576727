import React from 'react';
import styles from './radioBtn.module.scss';

interface iProps {
  className?: string;
  isChecked?: boolean;
  value: string;
  name?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function RadioButton(props: iProps): JSX.Element {
  const { className, isChecked, onChange, value, name } = props;

  return (
    <div className={`${className} ${styles.container}`}>
      <input
        type="radio"
        onChange={onChange}
        name={name}
        value={value}
        checked={isChecked}
      />
      <span className={styles.radioMark} />
    </div>
    // <div className={styles.customRadio}>
    //   <input type="radio" />
    //   <p className={styles.checked}></p>
    // </div>
  );
}

RadioButton.defaultProps = {
  className: '',
  isChecked: false,
  name: '',
};

export default RadioButton;
