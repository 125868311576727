import React from 'react';

interface iProps {
  className?: string;
}

function Connect(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#yhbpknzraa)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.04 2.423a.963.963 0 0 1 0 1.362l-3.22 3.22 3.84 3.838 3.219-3.219a.963.963 0 1 1 1.362 1.362l-3.22 3.22 1.92 1.92a.963.963 0 0 1 0 1.361l-3.25 3.25c-3.007 3.007-7.785 3.221-11.044.643L4.34 20.688a.963.963 0 0 1-1.362-1.362l1.308-1.309C1.707 14.76 1.92 9.98 4.927 6.974l3.25-3.25a.963.963 0 0 1 1.363 0l1.919 1.918 3.219-3.219a.963.963 0 0 1 1.362 0zm-5.286 5.239L8.858 5.767l-2.57 2.569c-2.48 2.482-2.483 6.546-.008 9.031l.009.009.008.008c2.486 2.476 6.55 2.473 9.032-.008l2.57-2.57-1.897-1.895a.893.893 0 0 1-.047-.048L10.802 7.71a.994.994 0 0 1-.048-.048z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="yhbpknzraa">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

Connect.defaultProps = {
  className: '',
};

export default Connect;
