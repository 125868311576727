export enum ClientTileVariants {
  SuperUserAdmin = 'superUserAdmin',
  SuperUserReseller = 'superUserReseller',
  ResellerClients = 'resellerClients',
}

export const Variants = {
  [ClientTileVariants.SuperUserAdmin]: {
    showClientsCount: false,
    showPlan: false,
  },
  [ClientTileVariants.SuperUserReseller]: {
    showClientsCount: true,
    showPlan: false,
  },
  [ClientTileVariants.ResellerClients]: {
    showClientsCount: false,
    showPlan: true,
  },
};
