import React from 'react';

interface iProps {
  className?: string;
}

function Dustbin(props: iProps): JSX.Element {
  const { className } = props;

  return (
    <svg
      className={className}
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 2c0-1.102.9-2 2-2h4c1.102 0 2 .898 2 2v.667h2.001A.667.667 0 0 1 14 4h-.037L13.4 14.104a.598.598 0 0 1-.002.023C13.303 15.171 12.51 16 11.4 16H4.6c-1.11 0-1.903-.83-1.998-1.873a.664.664 0 0 1-.001-.023L2.037 4H2a.667.667 0 0 1 0-1.333h2V2zm1.334.667h5.333V2a.67.67 0 0 0-.666-.667H6A.67.67 0 0 0 5.334 2v.667zM3.372 4l.56 10.017c.042.416.315.65.669.65h6.8c.353 0 .626-.234.668-.65L12.63 4H3.372z"
        fill="#8A8DAA"
      />
    </svg>
  );
}

Dustbin.defaultProps = {
  className: '',
};

export default Dustbin;
