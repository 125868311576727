import React from 'react';
import styles from './progressBar.module.scss';

interface iProps {
  className?: string;
  progress: number;
}

function ProgressBar(props: iProps): JSX.Element {
  const { className, progress } = props;
  return (
    <div className={`${styles.progressWrapper} ${className}`}>
      <div className={styles.progressBar}>
        <div className={styles.progress} style={{ width: `${progress}%` }} />
      </div>
      <span className={styles.size}>{progress}%</span>
    </div>
  );
}

ProgressBar.defaultProps = {
  className: '',
};

export default ProgressBar;
