import React from 'react';

interface iProps {
  className?: string;
}

function More(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <svg
      className={className}
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#c3s7kvybca)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.5 10c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm12 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm-6 0c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"
          fill="#615AE2"
        />
      </g>
      <defs>
        <clipPath id="c3s7kvybca">
          <path fill="#fff" transform="translate(.5)" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

More.defaultProps = {
  className: '',
};

export default More;
