import React from 'react';

interface iProps {
  className?: string;
}

function Information(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <svg
      className={className}
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.5 3.417c-9.435 0-17.083 7.648-17.083 17.083 0 9.435 7.648 17.083 17.083 17.083 9.435 0 17.083-7.648 17.083-17.083 0-9.435-7.648-17.083-17.083-17.083zM0 20.5C0 9.178 9.178 0 20.5 0S41 9.178 41 20.5 31.822 41 20.5 41 0 31.822 0 20.5zm20.5-10.25c.944 0 1.708.765 1.708 1.708v10.25a1.708 1.708 0 1 1-3.416 0v-10.25c0-.943.764-1.708 1.708-1.708zm0 17.083c.944 0 1.708.765 1.708 1.709v.017a1.708 1.708 0 0 1-3.416 0v-.017c0-.944.764-1.709 1.708-1.709z"
        fill="#5B5D70"
      />
    </svg>
  );
}

Information.defaultProps = {
  className: '',
};

export default Information;
