import { gql } from 'apollo-boost';

export enum SystemConfigs {
  GetSystemConfigs = 'getsystemconfigs',
  UpdateSystemConfig = 'updatesysconf',
}

export const getSystemConfigsQuery = gql`
  query ${SystemConfigs.GetSystemConfigs}{
    ${SystemConfigs.GetSystemConfigs} {
      statusCode
      message
      data {
        id
        sysc_key
        sysc_value
      }
    }
  } 
`;

export const updateSystemConfigMutation = gql`
  mutation ${SystemConfigs.UpdateSystemConfig}(
    $id: String!
    $sysc_key: String!
    $sysc_value: String
  ){
    ${SystemConfigs.UpdateSystemConfig}(
      id: $id
      sysc_key: $sysc_key
      sysc_value: $sysc_value
    ) {
      statusCode
      message
      data {
        id
        sysc_key
        sysc_value
      }
    }
  } 
`;
