import React from 'react';
import styles from './tableHeader.module.scss';

function TableHeader(): JSX.Element {
  return (
    <div className={styles.columns}>
      <div className={`${styles.column} ${styles.firstColumn}`}>Name</div>
      <div className={styles.column}>Languages</div>
      <div className={styles.column}>Last Updated</div>
    </div>
  );
}

export default TableHeader;
