import React from 'react';
import styles from './loader.module.scss';

interface iProps {
  className?: string;
  message?: string;
}

function Loader(props: iProps): JSX.Element {
  const { className, message } = props;
  return (
    <div className={`${styles.loader} ${className}`}>
      <p className={styles.loaderText}>Loading...</p>
      {message && <p className={styles.delayMessage}>{message}</p>}
    </div>
  );
}

Loader.defaultProps = {
  className: '',
  message: '',
};

export default Loader;
