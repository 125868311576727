import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './foldersSkeleton.module.scss';

interface iProps {
  className?: string;
}

function FoldersSkeleton(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <div className={`${styles.foldersSkeleton} ${className}`}>
      <Skeleton className={styles.folder} count={4} />
    </div>
  );
}

FoldersSkeleton.defaultProps = {
  className: '',
};

export default FoldersSkeleton;
