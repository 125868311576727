export enum AvatarVariants {
  Small = 'small',
  Medium = 'medium',
  SmallWithName = 'smallWithName',
  MediumWithName = 'mediumWithName',
  UnknownAvatar = 'unknownAvatar',
}

export const Variants = {
  [AvatarVariants.Small]: {
    showAvatar: true,
    showAvatarWithName: true,
  },
  [AvatarVariants.Medium]: {
    showAvatar: true,
    showAvatarWithName: true,
  },
  [AvatarVariants.UnknownAvatar]: {
    showAvatar: false,
    showAvatarWithName: false,
  },
};
