import React from 'react';
import styles from './avatar.module.scss';

function Avatar({ data }): JSX.Element {
  return (
    <div className={styles.avatarContainer}>
      <div className={styles.avatarCircle}>
        <div className={styles.avatarText}>
          {data.profilePic ? (
            <img src={data.profilePic} alt="" />
          ) : (
            <span>
              {(data.name && data.name[0]) || ''}
              {(data.lastName && data.lastName[0]) || ''}
            </span>
          )}
        </div>
      </div>
      <div className={styles.nameContainer}>
        <p className={styles.nameDetails}>
          {data.name}&nbsp;
          {data.lastName}
        </p>
        <p className={styles.emailDetails}>{data.email}</p>
      </div>
    </div>
  );
}

export default Avatar;
