import React, { useCallback } from 'react';
import {
  teamMembersIcon,
  securityIcon,
  caretFilledSmallDown,
} from 'constants/index';
import Button, { ButtonVariants } from 'components/button/button';
// import { UserRoles } from 'enums';
import styles from './inviteUser.module.scss';

interface iProps {
  className?: string;
  onClickInvite: () => void;
}

// const UserRoles = [
//   { id: '1', role: 'Super Admin' },
//   { id: '2', role: 'Admin' },
//   { id: '3', role: 'Reseller' },
//   { id: '4', role: 'User' },
// ];

function InviteUser(props: iProps): JSX.Element {
  const { className, onClickInvite } = props;

  const inviteUserHandler = useCallback(() => {
    onClickInvite();
  }, [onClickInvite]);

  return (
    <div className={`${styles.inviteUser} ${className}`}>
      <div className={styles.header}>
        <div className={styles.iconContainer}>
          <img src={teamMembersIcon} alt="" />
        </div>
        <div className={styles.teamMeta}>
          <p className={styles.heading}>Add Team Members</p>
          <p className={styles.description}>
            This user must be added to your Team to be able to access this
            folder
          </p>
        </div>
      </div>

      <div className={styles.roleSection}>
        <div className={styles.rolesHeader}>
          <img src={securityIcon} alt="" />
          <p>Role</p>
        </div>

        <div className={styles.rolesAndDescription}>
          <div className={styles.descriptions}>
            <p>Users can only work with content and playlists.</p>
            <p>Admins have all rights.</p>
          </div>
          <div className={styles.rolesDropdown}>
            <div className={styles.selectedOption}>
              <p>User</p>
              <img src={caretFilledSmallDown} alt="" />
            </div>

            <div className={styles.optionsContainer}>{/*  */}</div>
          </div>
        </div>
      </div>
      <div className={styles.footerSection}>
        <Button
          className={styles.sendInviteBtn}
          btnName="Send invite"
          variant={ButtonVariants.SmallStd}
          onClick={inviteUserHandler}
        />
      </div>
    </div>
  );
}

InviteUser.defaultProps = {
  className: '',
};

export default InviteUser;
