import React from 'react';

interface iProps {
  className?: string;
}

function Content(props: iProps): JSX.Element {
  const { className } = props;

  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 11a1 1 0 0 1 .79.386l7 9a1 1 0 1 1-1.58 1.228L10 13.629l-6.21 7.985a1 1 0 1 1-1.58-1.228l7-9A1 1 0 0 1 10 11z"
        fill="#ACAEC5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5c0-1.662 1.338-3 3-3h6a1 1 0 0 1 .707.293L12.414 5H21a3 3 0 0 1 3 3v11c0 1.652-1.348 3-3 3H3a3 3 0 0 1-3-3V5zm3-1c-.558 0-1 .442-1 1v14a1 1 0 0 0 1 1h18c.548 0 1-.452 1-1V8a1 1 0 0 0-1-1h-9a1 1 0 0 1-.707-.293L8.586 4H3z"
        fill="#ACAEC5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 11a1 1 0 1 0 0 2 1 1 0 0 0 0-2zm-3 1a3 3 0 1 1 6 0 3 3 0 0 1-6 0z"
        fill="#ACAEC5"
      />
    </svg>
  );
}

Content.defaultProps = {
  className: '',
};

export default Content;
