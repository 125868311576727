export const InvitedTableColumns = [
  {
    Header: 'Name',
    accessor: 'user',
  },
  {
    Header: 'Permission',
    accessor: 'permission',
  },
  {
    Header: 'Sent',
    accessor: 'sent',
  },
  {
    Header: 'Actions',
    accessor: 'actions',
  },
];

export const InvitedTempData = [
  {
    id: 1,
    name: { name: 'Peter Wilberg', email: 'peter@adagency.com' },
    permission: 'Full Access',
    sent: '01.01.2017  12:34',
    actions: '',
  },
  {
    id: 2,
    name: { name: 'Benito Hansen', email: 'benito@screenz.no' },
    permission: 'Full Access',
    sent: '01.01.2017  12:34',
    actions: '',
  },
];
