export enum LayoutVariants {
  layoutOne = 'layoutOne',
  layoutTwo = 'layoutTwo',
  layoutThree = 'layoutThree',
  layoutFour = 'layoutFour',
}

export const variants = {
  [LayoutVariants.layoutOne]: {
    sidebar: true,
    roleBasedHeader: false,
    header: false,
    subHeader: false,
    subHeaderTwo: false,
  },
  [LayoutVariants.layoutTwo]: {
    sidebar: true,
    roleBasedHeader: true,
    header: true,
    subHeader: true,
    subHeaderTwo: true,
  },
  [LayoutVariants.layoutThree]: {
    sidebar: true,
    roleBasedHeader: false,
    header: true,
    subHeader: false,
    subHeaderTwo: false,
  },
  [LayoutVariants.layoutFour]: {
    sidebar: true,
    roleBasedHeader: true,
    header: true,
    subHeader: false,
    subHeaderTwo: false,
  },
};
