import * as React from 'react';

interface iProps {
  className?: string;
}

function AirPlane(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <svg
      className={className}
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.627 1.339a.327.327 0 0 1 .205.029l11.402 5.96H4.61L1.377 1.9a.408.408 0 0 1 .06-.455.344.344 0 0 1 .19-.106zm2.985 7.323L1.377 14.1a.409.409 0 0 0 .06.455.335.335 0 0 0 .395.077l11.405-5.97H4.612zM16 7.997a1.189 1.189 0 0 1-.636 1.056L2.438 15.82a1.66 1.66 0 0 1-1.981-.36 1.741 1.741 0 0 1-.234-2.027l3.234-5.438L.223 2.568A1.741 1.741 0 0 1 .457.541 1.66 1.66 0 0 1 2.438.18l.007.003 12.919 6.753A1.203 1.203 0 0 1 16 7.988v.009z"
          fill="#111216"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

AirPlane.defaultProps = {
  className: '',
};

export default AirPlane;
