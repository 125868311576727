import React from 'react';
import Card from '../card/card';
import CompanyInformation from '../company-information/companyInformation';
import ContactInformation from '../contact-information/contactInformation';
import SectionHeading from '../section-heading/sectionHeading';
import styles from './general.module.scss';

interface iProps {
  className?: string;
}

function General(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <div className={`${styles.generalMainContent}  ${className}`}>
      <Card className="mb4">
        <div className={styles.container}>
          <SectionHeading heading="Company information" />
          <div>
            <CompanyInformation />
          </div>
        </div>
      </Card>

      <Card className="mb7">
        <div className={styles.container}>
          <SectionHeading heading="Contact information" />
          <div>
            <ContactInformation />
          </div>
        </div>
      </Card>
    </div>
  );
}

General.defaultProps = {
  className: '',
};

export default General;
