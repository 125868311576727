import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './listingSkeleton.module.scss';

interface iProps {
  className?: string;
}

function ListingSkeleton(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <div className={`${styles.listingSkeleton} ${className}`}>
      <Skeleton count={6} className={styles.list} />
    </div>
  );
}

ListingSkeleton.defaultProps = {
  className: '',
};

export default ListingSkeleton;
