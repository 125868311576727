import React from 'react';
// import QRCode from 'react-qr-code';
import styles from './authenticateWithApp.module.scss';

interface iProps {
  className?: string;
  qrCode: string;
  setCodeToVerify: (a: string) => void;
}

function AuthenticateWithApp(props: iProps): JSX.Element {
  const { className, qrCode, setCodeToVerify } = props;

  return (
    <div className={`${styles.authenticateWithApp} ${className}`}>
      <p className={styles.qrAuthHeading}>
        Set up two-factor authentication app.
      </p>
      <p className={styles.qrAuthDescription}>
        To be able to authorize with your app you need to scan this QR Code with
        your Authenticator App and enter the verification code below.
      </p>

      <div className={styles.qrContainer}>
        {/* <img className={styles.qrCode} src="" alt="" /> */}
        <img className={styles.qrCode} src={qrCode} alt="" />
        {/* <QRCode className={styles.qrCode} value="Screenz-app" size={100} /> */}
      </div>
      <p className={styles.verificationCode}>Verification Code</p>
      <input
        className={styles.codeInput}
        type="text"
        onChange={(event) => setCodeToVerify(event.target.value)}
      />
      <p className={styles.needHelp}>Need help?</p>
    </div>
  );
}

AuthenticateWithApp.defaultProps = {
  className: '',
};

export default AuthenticateWithApp;
