import React from 'react';

interface iProps {
  className?: string;
}

function Playlist(props: iProps): JSX.Element {
  const { className } = props;

  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7 3c-.558 0-1 .442-1 1h11a1 1 0 0 0-1-1H7zM4 4c0 .056.005.111.013.165A2.993 2.993 0 0 0 2 7c0 .056.005.111.013.165A2.993 2.993 0 0 0 0 10v10a3 3 0 0 0 3 3h18c1.652 0 3-1.348 3-3V10a3 3 0 0 0-3-3 3 3 0 0 0-2.014-2.834A1.01 1.01 0 0 0 19 4a3 3 0 0 0-3-3H7C5.338 1 4 2.338 4 4zm0 3c0-.558.442-1 1-1h13a1 1 0 0 1 1 1H4zm-2 3c0-.558.442-1 1-1h18a1 1 0 0 1 1 1v10c0 .548-.452 1-1 1H3a1 1 0 0 1-1-1V10zm9.897.352C10.736 9.507 9 10.274 9 11.8v6.4c0 1.526 1.736 2.293 2.897 1.448l.003-.002 4.361-3.198.001-.001a1.787 1.787 0 0 0 0-2.894L11.9 10.354l-.003-.002zM14.853 15 11 12.175v5.65L14.853 15z"
        fill="#ACAEC5"
      />
    </svg>
  );
}

Playlist.defaultProps = {
  className: '',
};

export default Playlist;
