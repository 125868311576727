import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import styles from './primaryDetails.module.scss';

interface iProps {
  className?: string;
}

function SkeletonPreLoader(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <div className={`${styles.primaryDetailsSkeleton} ${className}`}>
      <Skeleton className={styles.headSection} />
      <Skeleton count={3} className={styles.details} />
    </div>
  );
}

SkeletonPreLoader.defaultProps = {
  className: '',
};

export default SkeletonPreLoader;
