export enum PlaylistVariants {
  InPlaylist = 'inPlaylist',
  AssignContentModal = 'assignContentModal',
}

export const Variants = {
  [PlaylistVariants.InPlaylist]: {
    showCheckBox: false,
    showDesktopIcon: true,
    showSubPlaylistName: false,
  },
  [PlaylistVariants.AssignContentModal]: {
    showCheckBox: true,
    showDesktopIcon: false,
    showSubPlaylistName: true,
  },
};
