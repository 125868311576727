export enum UsersListVariants {
  SharedWithList = 'sharedWithList',
  ListToChoose = 'listToChoose',
}

export const Variants = {
  [UsersListVariants.ListToChoose]: {
    showEmail: true,
    showNae: true,
    showEdit: false,
    showOwnerTag: false,
    showInviteUser: true,
  },
  [UsersListVariants.SharedWithList]: {
    showEmail: false,
    showNae: true,
    showEdit: true,
    showOwnerTag: true,
    showInviteUser: true,
  },
};
