export enum FileUploadProgressVariants {
  InModal = 'inModal',
  OffModal = 'offModal',
}

export const Variants = {
  [FileUploadProgressVariants.InModal]: {
    showPreview: false,
    showContentIcon: true,
  },
  [FileUploadProgressVariants.OffModal]: {
    showPreview: true,
    showContentIcon: false,
  },
};
