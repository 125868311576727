import Button, { ButtonVariants } from 'components/button/button';
import { resellerEmptyStateImg, whiteAddIcon } from 'constants/index';
import { ModalViews, UserRoles } from 'enums';
import React, { useCallback } from 'react';
import { useStore } from 'store';
import { layoutActions } from 'store/actions';
import { iState } from 'types';
import styles from './emptyState.module.scss';

interface iProps {
  className?: string;
}

function EmptyState(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const { user } = state || {};

  const heading = 'Welcome to Screenz!';
  const description =
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.';

  const onClick = useCallback(() => {
    if (user.role === UserRoles.Reseller)
      dispatch(layoutActions.toggleModal(ModalViews.isShowAddClient));
  }, [dispatch, user]);

  return (
    <div className={`${className} ${styles.emptyState}`}>
      <div className={styles.descriptionArea}>
        <p className={styles.heading}>{heading}</p>
        <p className={styles.description}>{description}</p>
        <Button
          className={styles.screenzBtn}
          variant={ButtonVariants.SmallStd}
          btnName="Add your first client"
          icon={whiteAddIcon}
          onClick={onClick}
        />
      </div>
      <img
        className={styles.emptyStateImg}
        src={resellerEmptyStateImg}
        alt=""
      />
    </div>
  );
}

EmptyState.defaultProps = {
  className: '',
};

export default EmptyState;
