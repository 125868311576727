import ClientTile, {
  ClientTileVariants,
} from 'components/client-tile/clientTile';
import React from 'react';
import { useStore } from 'store';
import { iCompanyData, iState } from 'types';
import styles from './resellersList.module.scss';

interface iProps {
  className?: string;
}

function ResellerUserClients(props: iProps): JSX.Element {
  const { className } = props;
  const [state]: [iState] = useStore();
  const { superUserResellers } = state.homePage || {};

  return (
    <div className={`${className} ${styles.clientView}`}>
      {superUserResellers.map((i: iCompanyData) => (
        <ClientTile variant={ClientTileVariants.SuperUserReseller} data={i} />
      ))}
    </div>
  );
}

ResellerUserClients.defaultProps = {
  className: '',
};

export default ResellerUserClients;
