import React from 'react';

interface iProps {
  className?: string;
}

function PortraitScreen(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#cp220epxsa)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.75 2c.418 0 .75.332.75.75V14a.75.75 0 0 1-.75.75h-7.5c-.41 0-.75-.34-.75-.75V2.75A.75.75 0 0 1 5.25 2h7.5zm2.25.75A2.245 2.245 0 0 0 12.75.5h-7.5C4.013.5 3 1.5 3 2.75V14c0 1.24 1.01 2.25 2.25 2.25h7.5A2.25 2.25 0 0 0 15 14V2.75zM6.75 17a.75.75 0 0 0 0 1.5h4.5a.75.75 0 0 0 0-1.5h-4.5z"
          fill="#ACAEC5"
        />
      </g>
      <defs>
        <clipPath id="cp220epxsa">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h18v18H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

PortraitScreen.defaultProps = {
  className: '',
};

export default PortraitScreen;
