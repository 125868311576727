import React from 'react';

interface iProps {
  className?: string;
}

function Logo(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <svg
      className={className}
      viewBox="0 0 44 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#8lfc96ke1a)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M43.143 5.112c.117 1.392.2 6.984.2 8.388 0 1.392-.083 6.984-.2 8.388-.2 2.187-1.946 4.34-4.583 4.586-3.621.35-7.876.503-11.51.503-.023 0-2.625.023-5.262.023.375-.27.739-.526.891-.643 2.121-1.521 5.016-3.943 6.95-5.686 1.922-1.731 4.231-4 5.93-5.908 1.817-2.023.259-4.2-1.804-6.048-2.051-1.848-4.548-3.334-6.798-1.696-2.121 1.52-5.333 4.235-6.564 5.335-1.359 1.216-3.41 2.877-4.781 3.93-1.36 1.03-2.052.667-3.352-.491-1.29-1.158-1.735-1.79-.575-3.03A84.918 84.918 0 0 1 16.07 8.47c1.91-1.72 4.782-4.118 6.903-5.639.211-.164 2.38-1.813 3.704-2.808h.375c3.633 0 7.888.188 11.51.539a5.086 5.086 0 0 1 4.582 4.55zM16.619 26.977h-.375c-3.633 0-7.864-.152-11.474-.503-2.625-.246-4.383-2.399-4.582-4.586C.082 20.484 0 14.904 0 13.5c0-1.392.082-6.984.2-8.388A5.086 5.086 0 0 1 4.781.562C8.392.21 12.622.035 16.256.023c.024 0 2.649-.023 5.31-.023-.375.27-.739.526-.891.643-2.122 1.521-5.04 3.966-6.974 5.698-1.922 1.73-4.22 3.977-5.907 5.884-1.817 2.024-.258 4.188 1.805 6.036 2.051 1.849 4.536 3.323 6.774 1.673 2.145-1.544 5.146-4.024 6.564-5.3 1.36-1.216 3.387-2.9 4.77-3.942 1.383-1.052 2.121-.643 3.41.515 1.29 1.158 1.7 1.825.552 3.053-1.16 1.24-3.048 3.054-4.407 4.27-1.91 1.72-4.747 4.118-6.857 5.639a427.61 427.61 0 0 1-3.786 2.808z"
          fill="#615AE2"
        />
      </g>
      <defs>
        <clipPath id="8lfc96ke1a">
          <path fill="#fff" d="M0 0h43.342v27H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

Logo.defaultProps = {
  className: '',
};

export default Logo;
