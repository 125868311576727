import React from 'react';
import styles from './authenticateWithEmail.module.scss';

interface iProps {
  className?: string;
  email: string;
}

function AuthenticateWithEmail(props: iProps): JSX.Element {
  const { className, email } = props;

  return (
    <div className={`${styles.authenticateWithEmail} ${className}`}>
      <p className={styles.authWithMailHeading}>
        Set up two-factor authentication with Email.
      </p>
      <p className={styles.authWithMailDescription}>
        We’ll send you an email with a verification code. The email is sent to
        the email adress that is registrered on your Screenz account.
      </p>

      <p className={styles.verificationCodeToEmail}>
        Send an email to receive verification code
      </p>
      <input
        className={styles.emailInput}
        type="text"
        value={email}
        disabled
        placeholder="youremail@domain.com"
      />
    </div>
  );
}

AuthenticateWithEmail.defaultProps = {
  className: '',
};

export default AuthenticateWithEmail;
