import bottomPattern from 'assets/images/bottom_pattern.svg';
import topPattern from 'assets/images/top_pattern.svg';
import React from 'react';
import styles from './pattern.module.scss';

interface iProps {
  position: 'top' | 'bottom';
}

function Index(props: iProps): JSX.Element {
  const { position } = props;
  return (
    <img
      className={
        (position === 'top' && styles.topPattern) ||
        (position === 'bottom' && styles.bottomPattern)
      }
      src={
        (position === 'top' && topPattern) ||
        (position === 'bottom' && bottomPattern) ||
        ''
      }
      alt=""
    />
  );
}

export default Index;
