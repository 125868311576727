import React from 'react';
import { useStore } from 'store';
import { iState } from 'types';
import ResellerClients from './clients-list/clientsList';
import EmptyState from './empty-state/emptyState';
import styles from './resellerAdmin.module.scss';

interface iProps {
  className?: string;
}

function Client(props: iProps): JSX.Element {
  const { className } = props;
  const [state]: [iState] = useStore();
  const { homePage } = state || {};

  const { resellerClients } = homePage || {};

  return (
    <div className={`${className} ${styles.wrapper}`}>
      {resellerClients?.length === 0 ? <EmptyState /> : <ResellerClients />}
      {/* {screenzView === ScreenzViews.SCREENZ_DETAILS && <ScreensDetails />} */}
    </div>
  );
}

Client.defaultProps = {
  className: '',
};

export default Client;
