import { folder2Icon, playlistsIcon } from 'constants/index';

export enum AddModalVariants {
  AddClient = 'addClient',
  AddFolder = 'addFolder',
  AddPlaylistFolder = 'addPlaylistFolder',
  AddScreen = 'addScreen',
  AddPlaylist = 'addPlaylist',
  DuplicatePlaylist = 'duplicatePlaylist',
}

export const variants = {
  [AddModalVariants.AddPlaylist]: {
    heading: 'Create Playlist',
    btnName: 'Add Playlist',
    headingIcon: playlistsIcon,
    emptyErrorMessage: 'Please enter playlist name',
  },
  [AddModalVariants.DuplicatePlaylist]: {
    heading: 'Duplicate Playlist',
    btnName: 'Duplicate Playlist',
    headingIcon: playlistsIcon,
    emptyErrorMessage: 'Please enter playlist name',
  },
  [AddModalVariants.AddPlaylistFolder]: {
    heading: 'Create Folder',
    btnName: 'Add Folder',
    headingIcon: folder2Icon,
    emptyErrorMessage: 'Please enter folder name',
  },
};
