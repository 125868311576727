import React from 'react';

interface iProps {
  className?: string;
}

function User(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <svg
      className={className}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 6c0-1.658 1.342-3 3-3h12c1.658 0 3 1.342 3 3v12a2.996 2.996 0 0 1-1.352 2.507C18.622 17.78 15.438 16 12 16c-3.437 0-6.622 1.78-7.648 4.507a2.985 2.985 0 0 1-1.26-1.778A2.713 2.713 0 0 1 3 18V6zm3.321 15H17.68c-.764-1.64-2.883-3-5.679-3-2.796 0-4.915 1.36-5.679 3zM6 1C3.238 1 1 3.238 1 6v12c0 .412.048.84.164 1.258A4.992 4.992 0 0 0 6 23h12c2.762 0 5-2.238 5-5V6c0-2.762-2.238-5-5-5H6zm6 6a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm-5 3a5 5 0 1 1 10 0 5 5 0 0 1-10 0z"
        fill="#ACAEC5"
      />
    </svg>
  );
}

User.defaultProps = {
  className: '',
};

export default User;
