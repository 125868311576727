import emailIcon from 'assets/images/email-on-th-way.svg';
import Button, { ButtonVariants } from 'components/button/button';
import Logo from 'components/logo/logo';
import { refreshIcon, ROUTES } from 'constants/index';
import React, { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { userActions } from 'store/actions';
import { iState } from 'types';
import Pattern from '../components/pattern/pattern';
import styles from './verifyEmail.module.scss';

function ResetPassword(): JSX.Element {
  const navigate = useNavigate();
  const [state, dispatch]: [iState, any] = useStore();
  const { resetPasswordData } = state.user || {};

  useEffect(() => {
    if (!resetPasswordData) navigate(ROUTES.forgotPassword);
  }, [navigate, resetPasswordData]);

  const startOver = useCallback((): void => {
    dispatch(userActions.setResetPasswordData(null));
    navigate(ROUTES.forgotPassword);
  }, [navigate, dispatch]);

  return (
    <div className={`flex flex-col p3 ${styles.verifyEmailWrapper}`}>
      <Pattern position="top" />
      <Pattern position="bottom" />
      <section className={`flex-center flex-grow ${styles.section}`}>
        <div className={`${styles.verifyEmailContainer}`}>
          <Logo className={styles.logo} variant="onboarding" />
          <p className={styles.verify}>Verify your email</p>
          <img className={styles.emailIcon} src={emailIcon} alt="" />
          <p className={styles.paragraphOne}>
            Vi har sendt deg en epost til{' '}
            <span className={styles.email}>{resetPasswordData?.email}</span> med
            en link for å verifisere eposten din.
          </p>
          <p className={styles.paragraphTwo}>
            Det kan fort ta opp til 5 minutter før den kommer, hvis du ikke ser
            den, sjekke søppelposten din.
          </p>
          <Button
            className={styles.startOverAgain}
            btnName="Start over again"
            icon={refreshIcon}
            variant={ButtonVariants.MediumTransp}
            onClick={startOver}
          />
        </div>
      </section>
      {/* <Chat /> */}
    </div>
  );
}

export default ResetPassword;
