import React from 'react';
import styles from './pageWrapper.module.scss';

interface iProps {
  className?: string;
  children: JSX.Element;
}

function PageWrapper(props: iProps): JSX.Element {
  const { className, children } = props;
  return <div className={`${styles.pageWrapper} ${className}`}>{children}</div>;
}

PageWrapper.defaultProps = {
  className: '',
};

export default PageWrapper;
