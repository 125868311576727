import Connect from 'components/svg/connect';
import Disconnect from 'components/svg/disconnect';
import React from 'react';
import styles from './onlineStatusPill.module.scss';

interface iProps {
  className?: string;
  isOnline: boolean;
}

function OnlineStatusPill(props: iProps): JSX.Element {
  const { className, isOnline } = props;

  const StatusIcon = isOnline ? Connect : Disconnect;

  return (
    <div
      className={`${className} ${styles.connectionStatusPill} ${
        isOnline && styles.online
      }`}
    >
      <StatusIcon className={styles.disconnect} />
      <span>{isOnline ? 'Online' : 'Offline'}</span>
    </div>
  );
}

OnlineStatusPill.defaultProps = {
  className: '',
};

export default OnlineStatusPill;
