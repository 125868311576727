import { addContent } from 'apis';
import LabelAndInput from 'components/label-and-input/labelAndInput';
import Modal from 'components/modal/modal';
import { CONTENT_TYPE, refreshIcon, WebIcon } from 'constants/index';
import { toaster } from 'helpers';
import React, { useCallback, useEffect, useState } from 'react';
import { useStore } from 'store';
import { contentPageActions, layoutActions } from 'store/actions';
import { iState } from 'types';
import styles from './uploadContentWebpageModal.module.scss';

const urlReg =
  /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;

function UploadContentWebpageModal(): JSX.Element {
  const [state, dispatch]: [iState, any] = useStore();
  const [urlAddress, setUrlAddress] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [urlError, setUrlError] = useState<string>('');
  const [nameError, setNameError] = useState<string>('');
  const { layout, user, contentPage } = state || {};
  const { isShowAddWebContent } = layout || {};
  const { contentActiveFolder } = contentPage || {};

  useEffect(() => {
    const inputElement = document.getElementById('addURL');
    if (inputElement && isShowAddWebContent) {
      inputElement.focus();
    }
  }, [isShowAddWebContent]);

  const addWebpageHandler = useCallback(async () => {
    if (!urlAddress) {
      setUrlError('Please enter an URL');
    }
    if (!name) {
      setNameError('Please enter a name to remember');
      return null;
    }
    if (!urlAddress.match(urlReg)) {
      toaster.error(`${urlAddress} is not a valid url`);
      return null;
    }

    const { companyData } = state.homePage;
    if (!companyData) {
      toaster.error('Company data not found');
      return null;
    }
    const response = await addContent(
      {
        name,
        contentType: CONTENT_TYPE.Website,
        company: companyData.id,
        url: urlAddress,
        size: '',
        dimension: '',
        folder: contentActiveFolder?.id || null,
        thumbnailPath: '',
        user: user.loggedInData ? user.loggedInData?.id : '',
      },
      setIsLoading
    );
    if (response) {
      setUrlAddress('');
      setName('');
      dispatch(layoutActions.toggleModal());
      dispatch(contentPageActions.fetchContentsData(true));
      toaster.success('Webpage added successfully');
    }
    return null;
  }, [
    urlAddress,
    name,
    state.homePage,
    dispatch,
    contentActiveFolder?.id,
    user.loggedInData,
  ]);

  const refreshIntervals = [
    { id: '1', role: 'Do Not Reload' },
    { id: '2', role: 'Every 5 minutes' },
    { id: '3', role: 'Every 30 minutes' },
    { id: '4', role: 'Every hour' },
    { id: '3', role: 'Every 6 hours' },
    { id: '3', role: 'Every 12 hours' },
  ];

  const closeModal = useCallback(() => {
    dispatch(layoutActions.toggleModal());
    setUrlError('');
    setNameError('');
    setUrlAddress('');
    setName('');
  }, []);

  return (
    <Modal
      isOpen={isShowAddWebContent}
      heading="Add Webpage"
      btnName="Add Webpage"
      headingIcon={WebIcon}
      onClose={closeModal}
      submit={addWebpageHandler}
      isLoading={isLoading}
    >
      <div className={styles.uploadWebContent}>
        <LabelAndInput
          className={styles.input}
          name="name"
          id="addURL"
          label="URL"
          placeholder="https://www.example.com"
          value={urlAddress}
          onChange={(e) => {
            setUrlError('');
            setUrlAddress(e.target.value);
          }}
          errorMessage={urlError}
        />
        {/* <LabelAndInput
          className={styles.labelAndInput}
          label="URL"
          id="addURL"
          placeholder="https://www.example.com"
          value={urlAddress}
          onChange={(e) => {
            setUrlError('');
            setUrlAddress(e.target.value);
          }}
          errorMessage={urlError}
        /> */}
        <LabelAndInput
          className={styles}
          label="NAME"
          placeholder="Easy to remember"
          value={name}
          onChange={(e) => {
            setNameError('');
            setName(e.target.value);
          }}
          errorMessage={nameError}
        />
        <div className={styles.refreshContainer}>
          <div className={styles.refreshTitleContainer}>
            <img src={refreshIcon} alt="" />
            <p className={styles.refreshTitle}>Automatic Refresh</p>
          </div>
          <div>
            <select name="dropDown" className={styles.dropDown}>
              {refreshIntervals.map((role) => (
                <option value={role.id} className={styles.dropDownOptions}>
                  {role.role}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default UploadContentWebpageModal;
