import React from 'react';

interface iProps {
  className?: string;
  onClick?: () => void;
}

function Remote(props: iProps): JSX.Element {
  const { className, onClick } = props;
  return (
    <svg
      className={className}
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
    >
      <path
        d="M1 4.75A3.75 3.75 0 0 1 4.75 1h16.5A3.75 3.75 0 0 1 25 4.75v16.5A3.75 3.75 0 0 1 21.25 25H4.75A3.75 3.75 0 0 1 1 21.25V4.75z"
        fill="#ECE4FB"
      />
      <path
        d="M2.9 7.426a1 1 0 0 1 0-1.414 13.974 13.974 0 0 1 19.8 0 1 1 0 0 1-1.415 1.414c-4.7-4.7-12.27-4.7-16.97 0a1 1 0 0 1-1.415 0zM15 15a2 2 0 1 1-4 0 2 2 0 0 1 4 0zM13 20a1 1 0 1 0 0-2 1 1 0 0 0 0 2z"
        fill="#615AE2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 10h4.02c1.684 0 2.98 1.296 2.98 2.98v8.073c0 .022 0 .044-.002.066-.102 1.525-1.402 2.914-2.978 2.914H11c-1.684 0-2.98-1.296-2.98-2.98V12.98C8.02 11.296 9.316 10 11 10zm0 2c-.58 0-.98.4-.98.98v8.073c0 .579.4.98.98.98h4.02c.398 0 .925-.436.98-1.019V12.98c0-.58-.401-.98-.98-.98H11z"
        fill="#615AE2"
      />
      <path
        d="M5.729 8.84a1 1 0 1 0 1.414 1.414 7.963 7.963 0 0 1 11.314 0A1 1 0 0 0 19.87 8.84a9.963 9.963 0 0 0-14.142 0z"
        fill="#615AE2"
      />
      <path
        d="M4.75 1.75h16.5V.25H4.75v1.5zm19.5 3v16.5h1.5V4.75h-1.5zm-3 19.5H4.75v1.5h16.5v-1.5zm-19.5-3V4.75H.25v16.5h1.5zm3 3a3 3 0 0 1-3-3H.25a4.5 4.5 0 0 0 4.5 4.5v-1.5zm19.5-3a3 3 0 0 1-3 3v1.5a4.5 4.5 0 0 0 4.5-4.5h-1.5zm-3-19.5a3 3 0 0 1 3 3h1.5a4.5 4.5 0 0 0-4.5-4.5v1.5zM4.75.25a4.5 4.5 0 0 0-4.5 4.5h1.5a3 3 0 0 1 3-3V.25z"
        fill="#ECE4FB"
      />
    </svg>
  );
}

Remote.defaultProps = {
  className: '',
  onClick: () => {
    //
  },
};

export default Remote;
