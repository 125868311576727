import React from 'react';

interface iProps {
  className?: string;
}

function Power(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <svg
      className={className}
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#hls7yn2rda)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5 2.25a8.75 8.75 0 1 0 0 17.5 8.75 8.75 0 0 0 0-17.5zM0 11C0 5.201 4.701.5 10.5.5S21 5.201 21 11s-4.701 10.5-10.5 10.5S0 16.799 0 11zm10.5-6.125c.483 0 .875.392.875.875v4.375a.875.875 0 0 1-1.75 0V5.75c0-.483.392-.875.875-.875zm-3.26 3.18a.875.875 0 1 0-1.355-1.11C4.993 8.038 4.375 9.453 4.375 11c0 3.37 2.754 6.125 6.125 6.125 3.37 0 6.125-2.754 6.125-6.125 0-1.512-.507-2.952-1.54-4.089A.875.875 0 1 0 13.79 8.09c.717.788 1.085 1.798 1.085 2.911a4.39 4.39 0 0 1-4.375 4.375A4.39 4.39 0 0 1 6.125 11c0-1.077.432-2.112 1.115-2.946z"
          fill="#8A8DAA"
        />
      </g>
      <defs>
        <clipPath id="hls7yn2rda">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h21v21H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

Power.defaultProps = {
  className: '',
};

export default Power;
