import React from 'react';
import styles from './subheader.module.scss';

interface iProps {
  className?: string;
}

function Subheader(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <div className={`${styles.subheader} ${className}`}>
      <p className={styles.heading}>Empty States</p>
    </div>
  );
}

Subheader.defaultProps = {
  className: '',
};

export default Subheader;
