import React from 'react';
import Card from '../../card/card';
import Password from '../../password/password';
import SectionHeading from '../../section-heading/sectionHeading';
import TwoFactorAuthentication from '../../two-factor-authentication/twoFactorAuthentication';
import styles from './passwordAndSecurity.module.scss';

interface iProps {
  className?: string;
}

function PasswordAndSecurity(props: iProps): JSX.Element {
  const { className } = props;
  return (
    <div className={`${styles.mainContent}  ${className}`}>
      <Card className="mb4">
        <div className={styles.container}>
          <SectionHeading heading="Password" />
          <div>
            <Password />
          </div>
        </div>
      </Card>

      <Card className="mb7">
        <div className={styles.container}>
          <SectionHeading heading="2 Factor Authentication" />
          <div>
            <TwoFactorAuthentication />
          </div>
        </div>
      </Card>
    </div>
  );
}

PasswordAndSecurity.defaultProps = {
  className: '',
};

export default PasswordAndSecurity;
