export enum ModalVariants {
  Standard = 'standard',
  AddContentToPlaylist = 'addContentToPlaylist',
  AddContentPlaylist = 'addContentPlaylist',
}

export const Variants = {
  [ModalVariants.Standard]: {
    showSearchBar: false,
    showFilter: false,
    showUploadBtn: false,
    showAddBtn: false,
  },
  [ModalVariants.AddContentToPlaylist]: {
    showSearchBar: true,
    showFilter: true,
    showUploadBtn: true,
    showAddBtn: true,
  },
  [ModalVariants.AddContentPlaylist]: {
    showSearchBar: true,
    showFilter: false,
    showUploadBtn: false,
    showAddBtn: false,
  },
};
