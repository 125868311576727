import Popover from 'components/popover/popover';
import { caretFilledSmallDown, whiteCheckIcon } from 'constants/index';
import { uuid } from 'helpers';
import React, { useState } from 'react';
import styles from './colorPicker.module.scss';

interface iProps {
  className?: string;
}

const COLORS = [
  { id: uuid(), color: '#acaec5' },
  { id: uuid(), color: '#615ae2' },
  { id: uuid(), color: '#36af47' },
  { id: uuid(), color: '#de568c' },
  { id: uuid(), color: '#009d9f' },
  { id: uuid(), color: '#ffb700' },
  { id: uuid(), color: '#00affe' },
];

function Index(props: iProps): JSX.Element {
  const { className } = props;
  const [selectedColor, setSelectedColor] = useState<string>('#acaec5');

  return (
    <div className={`${styles.colorPicker}  ${className}`}>
      <p className={styles.colorText}>Playlist colour:</p>
      <Popover
        showArrow
        popoverClassName={styles.colorPickerPopover}
        popoverComponent={
          <ul className={styles.colors}>
            {COLORS.map((i) => (
              <li
                className={`${styles.colorWrapper} ${
                  i.color === selectedColor && styles.active
                }`}
                onClick={() => setSelectedColor(i.color)}
              >
                <span className={styles.color} style={{ background: i.color }}>
                  {i.color === selectedColor && (
                    <img src={whiteCheckIcon} alt="" />
                  )}
                </span>
              </li>
            ))}
          </ul>
        }
      >
        <button
          type="button"
          className={`${styles.colorChooseBtn} ${styles.headerBtns}`}
        >
          <span
            className={styles.choosenColor}
            style={{ background: selectedColor }}
          />
          <img src={caretFilledSmallDown} alt="" />
        </button>
      </Popover>
    </div>
  );
}

Index.defaultProps = {
  className: '',
};

export default Index;
