import Button, { ButtonVariants } from 'components/button/button';
import Logo from 'components/logo/logo';
import { ROUTES } from 'constants/index';
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './passwordResetInstructions.module.scss';

function PasswordResetInstructions(): JSX.Element {
  // const startOver = useCallback((): any => {
  //   navigate(ROUTES.forgotPassword, { replace: true });
  // }, []);

  return (
    <div className={`flex flex-col p3 ${styles.resetInstructionsWrapper}`}>
      <section className={`flex-center flex-grow ${styles.section}`}>
        <div className={`${styles.verifyEmailContainer}`}>
          <Logo className={styles.logo} variant="onboarding" />
          <p className={styles.heading}>
            Password reset instructions for your account at{' '}
            <span className={styles.screenz}>Screenz</span>.
          </p>
          <p className={styles.helloRuna}>
            Hello <Link to="/rune@screenz.io">rune@screenz.io</Link>!
          </p>
          <p className={styles.paragraphOne}>
            Someone has just requested a link to change your password, and you
            can do this through the link below.
          </p>
          <Link to={ROUTES.setNewPassword}>
            <Button
              className={styles.setNewPasswordBtn}
              btnName="Set new password"
              variant={ButtonVariants.MediumStd}
            />
          </Link>
          <p className={`mb1 ${styles.paragraphTwo}`}>
            If you didn't request this, please ignore this email.
          </p>
          <p className={`mb7 ${styles.paragraphTwo}`}>
            Your password won't change until you access the link above and
            create a new one.
          </p>
          <p className={styles.paragraphThree}>
            If you have any questions, please reply to this email or contact us
            at
            <Link className={styles.teamLink} to="/team@screenz.io">
              team@screenz.io
            </Link>
          </p>
          <hr className={styles.horizontal} />
          <span className={styles.copyright}>Copyright 2022 Screenz</span>
        </div>
      </section>
      {/* <Chat /> */}
    </div>
  );
}

export default PasswordResetInstructions;
