import { iPlaylistData } from 'types';

export const playlistTableColumns = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Setup',
    accessor: 'screenType',
  },
  {
    Header: 'Running time',
    accessor: 'duration',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'more',
    sortable: false,
  },
];

export const TEMP_COLUMNS = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Setup',
    accessor: 'screenType',
  },
  {
    Header: 'Running time',
    accessor: 'duration',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const TEMP_TABLE_DATA: iPlaylistData[] = [
  {
    id: '00011',
    name: 'Playlist Name',
    dimention: '',
    screenType: 'Landscape',
    duration: '00:05:32',
    companyId: '',
    status: '',
  },
  {
    id: '00022',
    name: 'Playlist Name',
    dimention: '',
    screenType: 'Landscape',
    duration: '00:05:32',
    companyId: '',
    status: '',
  },
  {
    id: '00033',
    name: 'Playlist Name',
    dimention: '',
    screenType: 'Landscape',
    duration: '00:05:32',
    companyId: '',
    status: '',
  },
  {
    id: '00044',
    name: 'Playlist Name',
    dimention: '',
    screenType: 'Landscape',
    duration: '00:05:32',
    companyId: '',
    status: '',
  },
  {
    id: '00055',
    name: 'Playlist Name',
    dimention: '',
    screenType: 'Landscape',
    duration: '00:05:32',
    companyId: '',
    status: '',
  },
  {
    id: '00066',
    name: 'Playlist Name',
    dimention: '',
    screenType: 'Landscape',
    duration: '00:05:32',
    companyId: '',
    status: '',
  },
  {
    id: '00077',
    name: 'Playlist Name',
    dimention: '',
    screenType: 'Landscape',
    duration: '00:05:32',
    companyId: '',
    status: '',
  },
  {
    id: '00088',
    name: 'Playlist Name',
    dimention: '',
    screenType: 'Landscape',
    duration: '00:05:32',
    companyId: '',
    status: '',
  },
  {
    id: '00099',
    name: 'Playlist Name',
    dimention: '',
    screenType: 'Landscape',
    duration: '00:05:32',
    companyId: '',
    status: '',
  },
  {
    id: '00010',
    name: 'Playlist Name',
    dimention: '',
    screenType: 'Landscape',
    duration: '00:05:32',
    companyId: '',
    status: '',
  },
  {
    id: '000101',
    name: 'Playlist Name',
    dimention: '',
    screenType: 'Landscape',
    duration: '00:05:32',
    companyId: '',
    status: '',
  },
  {
    id: '000132',
    name: 'Playlist Name',
    dimention: '',
    screenType: 'Landscape',
    duration: '00:05:32',
    companyId: '',
    status: '',
  },
  {
    id: '000431',
    name: 'Playlist Name',
    dimention: '',
    screenType: 'Landscape',
    duration: '00:05:32',
    companyId: '',
    status: '',
  },
  {
    id: '000513',
    name: 'Playlist Name',
    dimention: '',
    screenType: 'Landscape',
    duration: '00:05:32',
    companyId: '',
    status: '',
  },
];
