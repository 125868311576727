export enum ButtonVariants {
  ExSmallStd = 'ExSmallStd',
  SmallStd = 'smallStd',
  MediumStd = 'mediumStd',
  LargeStd = 'largeStd',
  ExSmallTransp = 'exSmallTransp',
  SmallTransp = 'smallTransp',
  MediumTransp = 'mediumTransp',
  LargeTransp = 'largeTransp',
  ChangeView = 'changeView',
}

export const Variants = {};
