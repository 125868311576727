import { ROUTES } from 'constants/paths';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useStore } from 'store';
import { userActions } from 'store/actions';
import { UserSettingsView } from 'enums';
import Avatar, { AvatarVariants } from 'components/avatar/avatar';
import { iState } from 'types';
import styles from './avatar.module.scss';

interface iProps {
  className?: string;
}

function AvatarAndName(props: iProps): JSX.Element {
  const { className } = props;
  const [state, dispatch]: [iState, any] = useStore();
  const navigate = useNavigate();
  const { firstName, lastName, profile_pic } = state.user.loggedInData || {};

  return (
    <div
      className={`${className} ${styles.profileWrapper}`}
      onClick={() => {
        navigate(ROUTES.userSettings, { state: { view: 'profile' } });
        dispatch(userActions.changeView(UserSettingsView.Profile));
      }}
    >
      <Avatar
        variant={AvatarVariants.Medium}
        className={styles.avatar}
        firstName={firstName}
        lastName={lastName}
        profilePic={profile_pic}
      />
      <p>{`${firstName} ${lastName}`}</p>
    </div>
  );
}
AvatarAndName.defaultProps = {
  className: '',
};
export default AvatarAndName;
